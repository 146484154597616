import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ToggleFirstLoading } from "../../Redux/User/user-actions";
import "./Intro.scss";

const Intro = () => {
    const dispatch = useDispatch();

    const logo = require(`../../Assets/wvst.png`).default;

    useEffect(() => {
        setTimeout(() => {
            dispatch(ToggleFirstLoading());
        }, 2500);
    }, []);


    return (
        <div className="intro">
            <div className="loading-wrapper">
                <div className="logo-container">
                    <img src={logo} alt="�ΰ�" className="logo"/>
                    <div className="shine"></div>
                </div>
            </div>
        </div>
    );
};

export default Intro;
