import { combineReducers } from "redux";
import streamerReducer from "./Streamer/streamer-reducer";
import userReducer from "./User/user-reducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["user", "streamer"]
};

const rootReducer = combineReducers({
    streamer: streamerReducer,
    user: userReducer
});

export default persistReducer(persistConfig, rootReducer);
