import React from "react";
import { Link } from "react-router-dom";
import "./NavMenu.scss";
import { withRouter } from "react-router";
import { selectCurrentUser } from "../../Redux/User/user-selectors";
import { connect } from "react-redux";
import { compose } from "redux";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleMenuHidden } from "../../Redux/User/user-actions";

const NavMenu = ({ ToggleMenuHidden }) => {
  return (
    <div className="overlay">
      <div className="overlay__exit" onClick={ToggleMenuHidden}>
        <FontAwesomeIcon icon={faTimes} className="overlay__exit-icon" />
      </div>
      <div className="overlay__content" onClick={ToggleMenuHidden}>
        <Link className="overlay__option" to="/all">
            등록선수
        </Link>
        <Link className="overlay__option" to="/one">
            Team 1
        </Link>

        <Link className="overlay__option" to="/two">
            Team 2
        </Link>

        <Link className="overlay__option" to="/three">
            Team 3
        </Link>

        <Link className="overlay__option" to="/four">
            Team 4
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  ToggleMenuHidden: () => dispatch(ToggleMenuHidden())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NavMenu);
