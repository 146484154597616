import StreamerActionTypes from './streamer-types';

const INITIAL_STATE = {
    streamerItems: [],
    scrollCount: 1
}

const streamerReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
	switch(type) {
        case StreamerActionTypes.GET_STREAMER_DATA: {
            return { ...state, streamerItems: payload, scrollCount: 1 };
        }
        case StreamerActionTypes.ADD_SCROLL_COUNT: {
            return { ...state, scrollCount: state.scrollCount + 1 };
        }
        default:
            return state;
	}
}

export default streamerReducer;