import React from "react";
import { Link } from "react-router-dom";
import LogoWVST from "../../Assets/wvst.png";
import "./Header.scss";
import { withRouter } from "react-router";
import {
    selectCurrentUser,
    selectToggleHidden
} from "../../Redux/User/user-selectors";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleMenuHidden } from "../../Redux/User/user-actions";
import { connect } from "react-redux";
import { compose } from "redux";
import NavMenu from "./NavMenu";

const Header = ({ history, hidden, ToggleMenuHidden }) => {

    return (
        <div className="header">
            <div className="header__mobile-logo-box" onClick={() => history.push("/all")}>
                <img src={LogoWVST} alt="logo" className="header__logo" />
            </div>
            <div className="header__options">
                <div className="header__options-primary">
                    <div className="header__logo-box" onClick={() => history.push("/all")}>
                        <img src={LogoWVST} alt="logo" className="header__logo" />
                    </div>
                    <li >
                        <Link className="header__option" to="/all">
                            등록선수
                        </Link>
                    </li>
                    <li>
                        <Link className="header__option" to="/one">
                            Team 1
                        </Link>
                    </li>
                    <li>
                        <Link className="header__option" to="/two">
                            Team 2
                        </Link>
                    </li>
                    <li>
                        <Link className="header__option" to="/three">
                            Team 3
                        </Link>
                    </li>
                    <li>
                        <Link className="header__option" to="/four">
                            Team 4
                        </Link>
                    </li >
                </div>
            </div>

            <FontAwesomeIcon
                icon={faBars}
                className="header__nav-menu-icon"
                onClick={ToggleMenuHidden}
            />
            {hidden ? null : <NavMenu />}


        </div>
    );
};

const mapStateToProps = state => ({
    currentUser: selectCurrentUser(state),
    hidden: selectToggleHidden(state)
});

const mapDispatchToProps = dispatch => ({
    ToggleMenuHidden: () => dispatch(ToggleMenuHidden())
});

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Header);
