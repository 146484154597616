import React, { Suspense } from "react";
import "./App.scss";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";

import Intro from "./components/Intro/Intro";
import Header from "./components/Header/Header";
import Spinner from "./components/Spinner/Spinner";

import { setCurrentUser, ToggleFirstLoading } from "./Redux/User/user-actions";
import { selectCurrentUser, selectToggleFirst } from "./Redux/User/user-selectors";

class App extends React.Component {
    unsubscribeFromAuth = null;

    constructor(props) {
        super(props);
        this.windowResize = this.windowResize.bind(this);
    }

    componentDidMount() {
        document.title = "홈 - 버축대 시즌2";
        window.addEventListener('resize', this.windowResize);

        if (window.location.pathname === '/' && this.props.firstLoading === false) {
            this.props.ToggleFirstLoading();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResize);
    }

    windowResize = () => {
    }

    render() {
        return (
            <div>
                {
                    this.props.firstLoading ?
                        <div className="start_container" style={{ display: this.props.firstLoading ? "block" : "none" }}>
                            <Intro />
                        </div>
                        : null
                }
                <div style={{ display: this.props.firstLoading ? "none" : "block" }}>
                    <Header />
                    <Suspense fallback={<Spinner />}>
                        <Switch>
                            <Route exact path="/" component={TeamComponent} />
                            <Route exact path="/all" component={TeamComponent} />
                            <Route exact path="/one" component={TeamOneComponent} />
                            <Route exact path="/two" component={TeamOneComponent} />
                            <Route exact path="/three" component={TeamOneComponent} />
                            <Route exact path="/four" component={TeamOneComponent} />
                        </Switch>
                    </Suspense>
                </div>
            </div>
        );
    }
}

const TeamComponent = React.lazy(() => import('./Pages/Team/Team'));
const TeamOneComponent = React.lazy(() => import('./Pages/Team/TeamOne'));

const mapStateToProps = state => ({
    currentUser: selectCurrentUser(state),
    firstLoading: selectToggleFirst(state),
});

const mapDispatchToProps = dispatch => ({
    setCurrentUser: user => dispatch(setCurrentUser(user)),
    ToggleFirstLoading: () => dispatch(ToggleFirstLoading()),
});

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(App);